html {
  height: 100%;
}

body {
  padding: 0px 0px;
  margin: 0px 0px;
  height: 100%;
}

*::-webkit-scrollbar {
  display: none;
}

.MuiModal-root {
  align-items: center;
  justify-content: center;
  display: flex;
}
.MuiModal-root .MuiBox-root {
  border-radius: 6px;

  padding: 16px 16px;

  max-width: calc(100vw - 60px) !important;
}

#root {
  height: 100%;
}
